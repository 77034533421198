/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const AxiosCustom = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

function onRequest(config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> {
  const token = localStorage.getItem('lf.accessToken'); // replace me 😣😣😣

  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }
  return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function onResponseError(error: AxiosError): Promise<AxiosError> {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

AxiosCustom.interceptors.request.use(onRequest, onRequestError);
AxiosCustom.interceptors.response.use(onResponse, onResponseError);

export default AxiosCustom;
