import { notification } from "antd";
import React, { useMemo } from "react";
import { RouterProvider } from "react-router";
import router from "router";
const Context = React.createContext({ name: 'Default' });
function App() {
  const [api, contextHolder] = notification.useNotification();
  const contextValue = useMemo(() => ({ name: 'YC app' }), []);
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <RouterProvider router={router} />
    </Context.Provider>
  );
}

export default App;
