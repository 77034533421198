import React, { useState } from 'react';
import './loginScreen.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, notification } from 'antd';
import { TypeAnimation } from 'react-type-animation';
import { TRegisterDataInput } from 'ts/types';
import AxiosCustom from 'apis/axiosCustom';

function RegisterScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  async function onFinish(values: TRegisterDataInput) {
    setIsLoading(true);
    try {
      const data = await AxiosCustom.post('register', { u: values.username, p: values.password });
      api.success({ message: "Notification", description: data.data.message });
    } catch (error: any) {
      api.error({ message: "Notification", description: error.response.data.error });
    }
    setIsLoading(false);
  };

  return <>
    {contextHolder}
    <div className='position-relative'>
      <div className="container" style={{ height: '100vh' }}>
        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
          <div className='ctn-form-login'>
            <div className='d-flex justify-content-center mb-5'>
              <TypeAnimation
                sequence={[
                  'Welcome to YC product',
                  1000,
                  'Welcome to creative',
                  1000,
                  'Welcome to website',
                  1000,
                  'Welcome to information',
                  1000
                ]}
                wrapper="div"
                speed={50}
                style={{ fontSize: '2em', display: 'inline-block', fontWeight: 'bold' }}
                repeat={Infinity}
                className='gradient-7-color'
              />
            </div>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your Password!' },
                  { min: 8, message: 'Password must be at least 8 characters long' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const confirmPassword = getFieldValue('confirmPassword');
                      if (!confirmPassword || value === confirmPassword) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords do not match!');
                    },
                  }),
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  { required: true, message: 'Please retype your Password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const password = getFieldValue('password');
                      if (!password || value === password) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords do not match!');
                    },
                  }),
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Retype Password"
                />
              </Form.Item>
              <Form.Item>
                <Button disabled={isLoading} type="primary" htmlType="submit" className="login-form-button">
                  {isLoading ? <Spin /> : "Register"}
                </Button>
                <span className='ms-1'>or</span>
                <Button disabled={isLoading} type='link' href='/auth/login' className="login-form-button">
                  {"Login now"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <img src="https://picsum.photos/1920/1080" alt="" className='position-absolute top-0 left-0' style={{ zIndex: -1, opacity: 0.4 }} />
    </div>
  </>
}
export default RegisterScreen;