import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from 'error';
import { MainLayout } from 'layout/MainLayout';
import { HomeScreen } from 'screen/home';
import LoginScreen from 'screen/auth/loginScreen';
import RegisterScreen from 'screen/auth/registerScreen';
import { AuthLayout } from 'layout/AuthLayout';
import { CrawlData } from 'screen/admin/crawl';
import ProfileDetail from 'screen/profileDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomeScreen />
      },
    ]
  },
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'auth/login',
        element: <LoginScreen />
      },
      {
        path: 'auth/register',
        element: <RegisterScreen />
      },
    ]
  },
  {
    path: 'admin/crawl',
    element: <CrawlData />,
    errorElement: <ErrorPage />
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: 'p/:profileId',
        element: <ProfileDetail />
      }
    ]
  },
  {
    path: '*',
    element: <div />
  }
]);

export default router;