import "./index.scss"
import { Avatar, List } from "antd";
import { CiUser } from "react-icons/ci";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { TSearchResultData } from "ts/types";
import AxiosCustom from "apis/axiosCustom";
import { useNavigate } from "react-router-dom";

export function HomeScreen() {
  const nv = useNavigate();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [studentData, setStudentData] = useState<TSearchResultData[]>([])
  async function searchData(value: string) {
    setIsSearching(true);
    try {
      const res = await AxiosCustom.get('/find', { params: { q: value } })
      setStudentData(res.data.listStudent)
    } catch (error) {

    }
    setIsSearching(false);
  }
  return <>
    <div className="ctm-home-page">
      <div className="container d-flex flex-column align-items-center">
        <div className="mb-5 d-flex flex-column align-items-center sticky" style={{ top: 40 }}>
          <div>
            <Search onSearch={searchData} placeholder="input search text" loading={isSearching} enterButton style={{ width: '100%', maxWidth: "300px" }} />
          </div>
          <div>
            <small style={{ color: '#848587' }}>*You can type student id, name or class id</small>
          </div>
        </div>
        <div className="list-wapper">
          <List
            itemLayout="horizontal"
            dataSource={studentData}
            renderItem={(item, index) => (
              <List.Item className="student-card" onClick={() => {
                nv(`/p/${item._id}`)
              }}>
                <List.Item.Meta
                  avatar={<Avatar icon={<CiUser />} size={50} src={`https://file.lhu.edu.vn/basic/StudentImage/${item.StudentID}.jpg`} />}
                  title={<span className="student-name">{item.HoTen}</span>}
                  description={item.LopID}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  </>
}