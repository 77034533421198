export const Major = {
  CT: "Information technology",
  EC: "Ecommerce",
  AI: "Artificial intelligence",
  MC: "Multimedia communications",
  OT: "Automotive technology",
  DC: "Electronic engineering technology",
  TD: "Control and automation technology",
  CD: "Mechatronics",
  QD: "Travel management and action",
  QT: "Business administration",
  LU: "Economic law",
  KQ: "International bussiness",
  MK: "Maketing",
  NT: "Foreign Economic Relations and Trade",
  DT: "Chinese",
  DN: "Japanese",
  DH: "Korean",
  AV: "English",
  NH: "Banking finance",
  KT: "Accounting and auditing",
  LG: "Logistics and supply chain",
  DS: "Pharmacist",
  XD: "construction engineering technology",
  XC: "Civil construction, road and bridge construction technology",
  TP: "Food Technology",
  MT: "Food quality and safety management",
}