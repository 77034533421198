export function CrawlData() {
  return (
    <div className="container">
      <div className="mb-1">Start number</div>
      <input className="mb-2" type="text" />
      <div className="mb-1">End number</div>
      <input className="mb-2" type="text" />
      <div className="mb-1">Token</div>
      <input className="mb-2" type="text" />
      <button>Crawl</button>
    </div>
  )
}