import React, { useMemo, useState } from 'react';
import './loginScreen.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { FcGoogle } from "react-icons/fc";
import { TypeAnimation } from 'react-type-animation';
import { TLoginDataInput } from 'ts/types';
import AxiosCustom from 'apis/axiosCustom';
import { useNavigate } from 'react-router-dom';

export default function LoginScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const navi = useNavigate();

  async function onFinish(values: TLoginDataInput) {
    setIsLoading(true);
    try {
      const data = await AxiosCustom.post('login', { u: values.username, p: values.password });
      localStorage.setItem('lf.accessToken', data.data.token);
      localStorage.setItem('currentUser', JSON.stringify(data.data));
      navi('/');
    } catch (error: any) {
      api.error({ message: "Notification", description: error.response.data.error });
    }
    setIsLoading(false);
  };
  return <>
    {contextHolder}
    <div className='position-relative'>
      <div className="container" style={{ height: '100vh' }}>
        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
          <div className='ctn-form-login'>
            <div className='d-flex justify-content-center mb-5'>
              <TypeAnimation
                sequence={[
                  'Welcome to Login',
                  1000,
                  'Welcome to YC product',
                  1000,
                  'Welcome to creative',
                  1000,
                  'Welcome to website',
                  1000,
                  'Welcome to information',
                  1000
                ]}
                wrapper="div"
                speed={50}
                style={{ fontSize: '2em', display: 'inline-block', fontWeight: 'bold' }}
                repeat={Infinity}
                className='gradient-7-color'
              />
            </div>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me.</Checkbox>
                </Form.Item>
                <span className='sp-forgot-pwd' onClick={() => { console.log('Forgot password clicked') }}>
                  Forgot password
                </span>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Log in
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type='dashed'>
                  <FcGoogle style={{ marginRight: 5 }} size={20} /> Log in with google
                </Button>
              </Form.Item>
              <Form.Item>
                <a href="/auth/register">register now!</a>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <img src="https://picsum.photos/1920/1080" alt="" className='position-absolute top-0 left-0' style={{ zIndex: -1, opacity: 0.4 }} />
    </div>
  </>
}